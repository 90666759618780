import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Dialog from "../ui/Dialog";

import Heading from "../ui/Heading";
import Paragraph from "../ui/Paragraph";
import Strong from "../ui/Strong";
import Icon from "../ui/Icon";
import TextLinkButton from "../ui/TextLinkButton";
import Label from "../ui/Label";
import InputFeatured from "../ui/InputFeatured";
import InputError from "../ui/InputError";
import Button from "../ui/ButtonMain";
import Divider from "../ui/Divider";

import { loginUser, logoutUser, getCSRF } from "../actions/appState";

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

class Login extends Component {
  state = {
    showPassword: false,
  };

  componentDidMount() {

    console.log(
      "componentDidMount", 
      (!this.props.hasValidSession),
      (!this.props.user)
    );

    if (
      (!this.props.hasValidSession) ||
      (!this.props.user)
    ) {
      this.props.getCSRF();
    }
  }

  
  componentDidUpdate(prevProps) {

    console.log(
      "componentDidUpdate", 
      (prevProps.hasValidSession && !this.props.hasValidSession),
      (prevProps.user && !this.props.user)
    );

    if (
      (prevProps.hasValidSession && !this.props.hasValidSession) ||
      (prevProps.user && !this.props.user)
    ) {
      this.props.getCSRF();
    }
  }

  
  render() {
    const { hasValidSession, isValidatingSession, user } = this.props;
    return (
      <Dialog title={""} isShown={!hasValidSession} hideFooter narrow>
        <div>
          {user && user.id ? (
            <>
              <Heading size="h3">Confirm your password</Heading>
              <Paragraph className="text-gray-500">
                Your session has expired due to inactivity. You can continue
                where you left off by logging in again.
              </Paragraph>
            </>
          ) : (
            <>
              <Heading size="h3">Please Login</Heading>
              <Paragraph className="text-gray-500 mt-2 mb-4">
                Please enter your credentials to access the portal.
              </Paragraph>
            </>
          )}
          <Divider />

          <Formik
            initialValues={{
              email: user && user.email ? user.email : "",
              password: "",
              remember_me: false,
            }}
            validationSchema={ValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, actions) => {
              // same shape as initial values
              this.props.loginUser(values);
            }}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="w-full">
                {!user || !user.email ? (
                  <div className="mb-3">
                    <Label>Email Address</Label>
                    <InputFeatured
                      autoFocus
                      type="text"
                      name="email"
                      placeholder="Enter your Email Address"
                      icon="envelope"
                    />
                    <InputError>
                      {errors.email ? errors.email : null}
                    </InputError>
                  </div>
                ) : (
                  <div
                    className={
                      "flex flex-col flex-1 w-full rounded border border-gray-100 bg-gray-50 relative p-2 mb-3 "
                    }
                  >
                    <div className="flex flex-row justify-between text-sm">
                      <Paragraph className="text-gray-600">
                        You're currently logged in as
                      </Paragraph>
                      <TextLinkButton onClick={this.props.logoutUser}>
                        Not you?
                      </TextLinkButton>
                    </div>
                    <Paragraph>
                      <Strong>{user.display_name}</Strong>
                    </Paragraph>
                    <Paragraph>{user.email}</Paragraph>
                  </div>
                )}
                <div className="mb-3">
                  <div className="flex flex-row justify-between relative">
                    <Label>Password</Label>
                    <button
                      type="button"
                      className="absolute top-0 right-0 p-px text-gray-500 focus:outline-none focus:shadow-outline disabled:opacity-25"
                      onClick={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                    >
                      <Icon
                        fixedWidth
                        className="text-gray-400"
                        icon={[
                          "far",
                          this.state.showPassword ? "eye-slash" : "eye",
                        ]}
                      />
                    </button>
                  </div>
                  <InputFeatured
                    type={this.state.showPassword ? "text" : "password"}
                    name="password"
                    hasError={false}
                    placeholder="Enter your Password"
                    icon="lock-alt"
                  />
                  <InputError>
                    {errors.password ? errors.password : null}
                  </InputError>
                </div>

                {(!user || !user.email) && (
                  <div className="mb-3">
                    <Label htmlFor="remember_me">Stay signed in?</Label>
                    <Label
                      className={
                        "flex flex-col flex-1 w-full rounded border border-gray-100 bg-gray-50 relative p-2 "
                      }
                      htmlFor="remember_me"
                    >
                      <span className={"flex flex-row px-2 py-2 "}>
                        <Field
                          name="remember_me"
                          type="checkbox"
                          id="remember_me"
                          className="input-checkbox mt-1 mr-4"
                        />
                        <span>
                          <p className="text-gray-500 text-sm">
                            Check this to reduce the number of times you are
                            asked to sign in.
                          </p>
                        </span>
                      </span>
                    </Label>
                  </div>
                )}

                <Divider />
                <div className="">
                  {isValidatingSession && (
                    <Button disabled appearance="dark" type="button">
                      Verifying...
                    </Button>
                  )}

                  {!isValidatingSession && (
                    <Button appearance="dark" type="submit">
                      Login
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    hasValidSession: store.appState.hasValidSession,
    isValidatingSession: store.appState.isValidatingSession,
    user: store.appState.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCSRF: (payload, meta) => dispatch(getCSRF(payload, meta)),
  loginUser: (payload, meta) => dispatch(loginUser(payload, meta)),
  logoutUser: (payload, meta) => dispatch(logoutUser(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
